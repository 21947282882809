import React from "react";
import { Link } from "gatsby";
import Layout, { ContentSection } from "../components/layout";
import { H1, Button, FlexContainer } from "@lewisblackwood/component-library";

const NotFoundPage = () => (
  <Layout>
    <ContentSection>
      <FlexContainer
        justifyContent="center"
        alignItems="center"
        height="50vh"
        flexDirection="column"
      >
        <H1 size="xxlarge" sizeMobile="xlarge" center mb="xlarge">
          Page not found!
        </H1>
        <Link to="/">
          <Button
            secondary
            height="48px"
            width="240px"
            size="large"
            bold
            alt="Go to the homepage"
          >
            Return to homepage
          </Button>
        </Link>
      </FlexContainer>
    </ContentSection>
  </Layout>
);

export default NotFoundPage;
